(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sbInternalMsgBus"), require("sbMsgBusModule"));
	else if(typeof define === 'function' && define.amd)
		define("sbBetslipUtils", ["sbInternalMsgBus", "sbMsgBusModule"], factory);
	else if(typeof exports === 'object')
		exports["sbBetslipUtils"] = factory(require("sbInternalMsgBus"), require("sbMsgBusModule"));
	else
		root["sbBetslipUtils"] = factory(root["sbInternalMsgBus"], root["sbMsgBusModule"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__) {
return 